/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import usePrintRecordMutation from "../../api/hooks/stocks/usePrintRecordMutation";
import { useAxios } from "../../api/hooks/useApi";
import PrintableContent from "./printContent";
import { useRecoilState } from "recoil";
import { permissionState } from "../../provider/atoms";
import { toast } from "react-toastify";

export default function PrintAll({ nameProduct, params, refetch }: any) {
  const api = useAxios();
  const printableContentRef = useRef<any>(null);
  const [dataPermission] = useRecoilState<any>(permissionState);
  const { mutateAsync } = usePrintRecordMutation();
  const [type, setType] = useState<number>();
  const printHandlerSmall = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      margin: 230px 55px 0px 48px !important;
    }    
    @media print {    
      html, body {
        height: initial !important;
        margin: 0px 19px 0px 25px !important;      
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        margin: 0px 19px 0px 25px !important;       
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          params?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: params?.id,
        is_printAll: true,
      }).then(() => {
        refetch();
      });
    },
  });

  // Print Barcode Gede
  const printHandlerBig = useReactToPrint({
    pageStyle: `
    @page {
      size: auto;
      margin: 205px 30px 0px 30px !important;
    }
    @media print {
      html, body {
        height: initial !important;
        margin: 0px 19px 0px 25px !important;
        padding: 0 !important;
        overflow: hidden;
      }
      .page-break {
        margin: 0px 19px 0px 25px !important;
        display: block;
        page-break-after: always;
      }
    }`,
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          params?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: params?.id,
        is_printAll: true,
      }).then(() => {
        refetch();
      });
    },
  });
  const printhandler = useReactToPrint({
    content: () => printableContentRef.current,
    onAfterPrint() {
      mutateAsync({
        type:
          params?.type === "ro"
            ? "receive_order_detail_id"
            : "stock_product_unit_id",
        id: params?.id,
        is_printAll: true,
      }).then(() => refetch());
    },
  });
  const typeStock =
    params?.type === "ro"
      ? "filter[receive_order_detail_id]"
      : "filter[stock_product_unit_id]";
  const [data, setData] = useState<any>();
  const handlePrint = async () => {
    try {
      const res = await api.get(`stocks/print-all`, {
        params: {
          [typeStock]: params?.id,
        },
      });
      console.log(res, "printAll");
      setData(res.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };
  const handleAllPrint = (data: any) => {
    api
      .post(`stocks/set-to-printing-queue`, {
        stocks: data,
      })
      .then((res) => {
        refetch()
        toast("Set to print");
      })
      .catch((err) => {
        toast(err?.axiosError?.response?.data?.message);
        console.log(err);
      });
  };
  console.log(data);
  useEffect(() => {
    if (data && data.length > 0) {
      // printHandlerSmall();
      const newData = data.map((e: { id: any }) => e.id);
      handleAllPrint(newData);
    }
  }, [data]);
  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     if (type === 1) {
  //       printHandlerSmall();
  //     } else {
  //       printHandlerBig();
  //     }
  //   }
  // }, [data]);
  return (
    <>
      <div className="print-content">
        <PrintableContent
          ref={printableContentRef}
          data={data}
          multiple
          type={2}
          name={nameProduct}
        />
      </div>
      <Button
        w={"10rem"}
        ml={10}
        bg={"#01338C"}
        color={"#fff"}
        onClick={handlePrint}
        isDisabled={!dataPermission?.stock_access?.stock_print}
      >
        Print All
      </Button>
    </>
  );
}
