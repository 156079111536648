/* eslint-disable react-hooks/exhaustive-deps */
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import useReceiveStockDetail from "../../api/hooks/stocks/useReceiveStockDetail";
import Header from "../../components/molecules/header";
import { GroupingStock } from "../../components/molecules/modal/Grouping";
import Pagination from "../../components/molecules/pagination";
import PrintAll from "../../components/molecules/printAll";
import TableStockDetail from "../../components/molecules/tables/tableStockDetail";
import { Button, Flex } from "@chakra-ui/react";
import PrintSelected from "../../components/molecules/printSelected";
import { COLOR_PRIMARY } from "../../Helper/theme";

function StockDetail() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<[]>([]);
  const [start, setStart] = useState("");
  const [pagination, setPagination] = useState(20);
  const [end, setEnd] = useState("");
  const [filterGroup, setFilterGroup] = useState(0);
  const handleSearch = debounce((searchTerm) => {
    console.log(`Searching for ${searchTerm}...`);
  }, 500);
  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    setName(searchTerm);
    handleSearch(searchTerm);
  };
  const location = useLocation();
  const dataProps = location.state;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const typeStock =
    dataProps?.type === "ro"
      ? "filter[receive_order_detail_id]"
      : "filter[stock_product_unit_id]";
  const { data, refetch } = useReceiveStockDetail({
    "filter[start_date]": start,
    "filter[end_date]": end,
    "filter[is_show_group]": filterGroup,
    page: currentPage,
    per_page: pagination,
    [typeStock]: dataProps?.id,
  });
  const countData = data?.pages[0].meta.total;
  const handlePagination = useCallback(
    (data: SetStateAction<number>) => {
      setCurrentPage(data);
    },
    [setCurrentPage]
  );

  useEffect(() => {
    refetch();
  }, [currentPage, filterGroup, pagination]);
  const dataList = data?.pages.flatMap((page) => page.data);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <Header headerName={dataProps.name} back={true} navName={"/shipment"} />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          background: "#fff",
        }}
      >
        <div className="body">
          <h3 className="bodyTextH3">Category</h3>
          <h2 className="font-medium">{dataProps?.category}</h2>
        </div>

        <div className="body">
          <h3 className="bodyTextH3">Brand</h3>
          <h2 className="font-medium">{dataProps?.brand}</h2>
        </div>

        <div className="body">
          <h3 className="bodyTextH3">Qty</h3>
          <h2 className="font-medium">
            {dataProps?.adjust_qty === 0 || dataProps?.adjust_qty === undefined
              ? dataProps?.qty
              : dataProps?.adjust_qty}
          </h2>
        </div>
      </div>

      <Flex justifyContent={"space-between"}>
        <div>
          <GroupingStock
            data={dataProps}
            refetch={refetch}
            type={
              dataProps?.type === "ro"
                ? "receive_order_detail_id"
                : "stock_product_unit_id"
            }
          />
          <PrintAll
            nameProduct={dataProps?.name}
            params={dataProps}
            refetch={refetch}
          />
          <PrintSelected
            refetch={refetch}
            nameProduct={dataProps?.name}
            data={selectedRows}
            props={dataProps}
            setSelectedRows={setSelectedRows}
            isActive={selectedRows.length === 0 ? true : false}
          />
          <Button
            w={"10rem"}
            ml={5}
            bg={"#01338C"}
            color={"#fff"}
            onClick={() => setFilterGroup(filterGroup === 0 ? 1 : 0)}
          >
            {filterGroup === 0 ? "Show Group" : "Show All"}
          </Button>
          <Button
            w={"10rem"}
            ml={5}
            bg={"#e67e22"}
            color={"#fff"}
            onClick={() =>
              navigate("/stock-product/scan", {
                state: {},
              })
            }
          >
            Scan Product
          </Button>
          <Button
            w={"5vw"}
            ml={5}
            bg={"#c4c4c4"}
            color={pagination === 50 ? COLOR_PRIMARY : "#fff"}
            onClick={() => setPagination(50)}
          >
            50
          </Button>
          <Button
            w={"5vw"}
            ml={5}
            bg={"#c4c4c4"}
            color={pagination === 100 ? COLOR_PRIMARY : "#fff"}
            onClick={() => setPagination(100)}
          >
            100
          </Button>
        </div>
      </Flex>
      <p className="ml-8 ">Filter : </p>
      <Flex className="items-center ml-8  ">
        <input
          type="date"
          id="start"
          name="start"
          value={start}
          className="form-control border-[2px] p-1 rounded-md"
          placeholder="Start Month"
          onChange={(start) => setStart(start?.target?.value)}
        />
        <div className="input-group-addon" style={{ margin: "0 10px" }}>
          to
        </div>
        <input
          type="date"
          id="end"
          name="end"
          value={end}
          className="form-control border-[2px] p-1 rounded-md"
          placeholder="End Month"
          onChange={(end) => setEnd(end?.target?.value)}
          style={{ margin: "0" }}
        />
        <Button ml="1rem" onClick={() => refetch()}>
          Filter
        </Button>
      </Flex>
      <TableStockDetail
        refetch={refetch}
        name={dataProps?.name}
        data={dataList}
        props={dataProps}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Pagination
        pagesQuantity={countData ? Math.ceil(countData / pagination) : 0}
        handlePagination={handlePagination}
      />
    </div>
  );
}

export default StockDetail;
