import { Box, Button, FormControl, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR_YELLOW } from "../../Helper/theme";
import useUpdateProductUnit from "../../api/hooks/product/useUpdateProductUnit";
import { DashboardContainer } from "../atoms/dashboardContainer";
import Header from "../molecules/header";
import { SelectPackagingProductUnit } from "../molecules/selectPackagingProduct";
import useUomsList from "../../api/hooks/uoms/useUomsList";
import { Select } from "chakra-react-select";

function FormUpdateProductUnit() {
  const { mutateAsync } = useUpdateProductUnit();
  const location = useLocation();
  const dataProps = location.state;

  const [items, setItems] = useState<any>({});
  const [uoms, setUoms] = useState<any>({
    id: dataProps?.uom?.id,
    label: dataProps?.uom?.name,
  });
  const nav = useNavigate();
  const [form, setForm] = useState({
    name: dataProps?.name,
    description: dataProps?.description,
    price: dataProps?.price,
  });
  const handleSubmit = () => {
    mutateAsync({
      id: dataProps?.id,
      name: form.name,
      description: form.description,
      price: form.price,
      product_id: dataProps?.product?.id,
      code: dataProps?.code,
      uom_id: uoms.id,
      package_id: items.id,
    });
  };

  console.log(uoms);
  return (
    <div>
      <Header back={true} headerName="Update Product Unit" />

      <Text py={3} fontWeight={"bold"} fontSize={"1em"}>
        Name
      </Text>
      <Input
        placeholder="input name here"
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
      />
      <Box mt={5}>
        <SelectPackagingProductUnit
          selectedProducts={items}
          setSelectedProduct={(e: any) => setItems(e)}
        />
      </Box>
      <Text py={3} fontWeight={"bold"} fontSize={"1em"}>
        Uoms
      </Text>
      <UomPicker selected={uoms} setSelected={(e: any) => setUoms(e)} />

      <Text py={3} fontWeight={"bold"} fontSize={"1em"}>
        Description
      </Text>
      <Input
        placeholder="input description here"
        value={form.description}
        mt={"0.5rem"}
        onChange={(e) => setForm({ ...form, description: e.target.value })}
      />

      <Text py={3} fontWeight={"bold"} fontSize={"1em"}>
        Price
      </Text>
      <Input
        placeholder="input price here"
        value={form.price}
        mt={"0.5rem"}
        onChange={(e) => setForm({ ...form, price: e.target.value })}
      />

      <Button
        size={"md"}
        onClick={handleSubmit}
        mt={"0.5rem"}
        bg={COLOR_YELLOW}
        color="#fff"
      >
        Update
      </Button>
    </div>
  );
}

const UomPicker = ({ selected, setSelected }: any) => {
  const { data } = useUomsList({});
  const dataUoms = data?.pages.flatMap((page) => page.data);
  const [search, setSearch] = useState<any>();
  return (
    <Box>
      <Select
        inputValue={search}
        onInputChange={(e) => setSearch(e)}
        name="colors"
        className="bg-white"
        options={dataUoms?.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))}
        placeholder="Select Uoms"
        closeMenuOnSelect={true}
        value={selected}
        onChange={setSelected}
      />
    </Box>
  );
};

export default FormUpdateProductUnit;
