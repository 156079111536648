import { createBrowserRouter } from "react-router-dom";
import FormProduct from "../components/forms/productForm";
import Login from "../screens/Auth";
import BrandMaster from "../screens/Brand";
import UpdateBrand from "../screens/Brand/updateBrand";
import CategoryMaster from "../screens/Category";
import UpdateCategory from "../screens/Category/updateCategory";
import Customer from "../screens/Customer";
import NewCustomer from "../screens/Customer/newCustomer";
import UpdateCustomer from "../screens/Customer/updateCustomer";
import Delivery from "../screens/Delivery/delivery";
import DeliveryDetail from "../screens/Delivery/deliveryDetail";
import Home from "../screens/Home";
import HomeMobile from "../screens/Home/mobile";
import ProductMaster from "../screens/Product";
import ProductBlackList from "../screens/Product/productBlackList";
import ProductDetail from "../screens/Product/productDetail";
import QRMobile from "../screens/QRMobile";
import OpnameScanner from "../screens/QRMobile/qrOpname";
import StockScanner from "../screens/QRMobile/qrStock";
import ReceiveOrder from "../screens/ReceiveOrder";
import ReceiveOrderDetail from "../screens/ReceiveOrder/receiveOrderDetail";
import ReturnScreen from "../screens/Return";
import NewReturn from "../screens/Return/newReturn";
import ReturnDetail from "../screens/Return/returnDetail";
import Roles from "../screens/Roles";
import EditRoles from "../screens/Roles/EditRoles";
import NewRoles from "../screens/Roles/NewRoles";
import Sales from "../screens/Sales";
import EditShipment from "../screens/Sales/editShipment";
import SalesOrderMobile from "../screens/Sales/mobile";
import NewShipment from "../screens/Sales/newShipment";
import SalesOrderDetail from "../screens/Sales/salesOrderDetail";
import AddStock from "../screens/Stock";
import AdjustmentScreen from "../screens/Stock/AdjustmentScreen";
import Stock from "../screens/Stock/stock";
import StockDetail from "../screens/Stock/stockDetail";
import StockMovement from "../screens/Stock/stockMovement";
import Supplier from "../screens/Supplier";
import NewSupplier from "../screens/Supplier/newSupplier";
import Users from "../screens/User";
import NewUser from "../screens/User/NewUser";
import UpdateUser from "../screens/User/UpdateUser";
import EditSupplier from "../screens/Supplier/editSupplier";
import Warehouse from "../screens/warehouse";
import StockOpname from "../screens/opname";
import StockOpnameDetails from "../screens/opname/stockOpnameDetails";
import UpdateProductUnit from "../screens/Product/editProductDetail";
import UpdateWarehouse from "../screens/warehouse/UpdateWarehouse";
import NewShipmentTest from "../screens/Sales/newShipmentTest";
import DeliveryOrderUpdate from "../components/forms/DeliveryOrderUpdateForm";
import ProductUoms from "../screens/Uoms";
import InvoiceScreen from "../screens/Invoice";
import AddInvoiceScreen from "../screens/Invoice/AddInvoiceScreen";
import InvoiceDetailScreen from "../screens/Invoice/invoiceDetailScreen";
import PaymentDetailScreen from "../screens/Invoice/PaymentDetailScreen";
import MasterScreen from "../screens/Master";
import VoucherScreen from "../screens/Master/Voucher";
import BatchAndCategory from "../screens/Master/Voucher/batchAndCategoryScreen";
import MobileInvoiceList from "../screens/Invoice/mobile/homeSPG";
import InvoiceListScreen from "../screens/Invoice/mobile/invoiceListScreen";
import InvoiceCreateScreen from "../screens/Invoice/mobile/invoiceCreateList";
import MobileInvoiceDetailScreen from "../screens/Invoice/mobile/invoiceDetailScreen";
import EditInvoiceScreen from "../screens/Invoice/EditInvoiceScreen";
import StockMobileScreen from "../screens/Stock/mobile/stockMobileScreen";
import ProductUnit from "../screens/Invoice/mobile/ProductUnits";
import ScannerScreen from "../screens/Stock/ScannerScreen";
export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/",
        element: <Stock />,
      },
      {
        path: "/mobile/home",
        element: <HomeMobile />,
      },
      {
        path: "/addStock",
        element: <AddStock />,
      },
      {
        path: "/return",
        element: <ReturnScreen />,
      },
      {
        path: "/return/detail",
        element: <ReturnDetail />,
      },
      {
        path: "/return/new-return",
        element: <NewReturn />,
      },
      {
        path: "/supplier",
        element: <Supplier />,
      },
      {
        path: "/new-supplier",
        element: <NewSupplier />,
      },
      {
        path: "/edit-supplier",
        element: <EditSupplier />,
      },
      {
        path: "/customer",
        element: <Customer />,
      },
      {
        path: "/customer/update-customer",
        element: <UpdateCustomer />,
      },
      {
        path: "/product",
        element: <ProductMaster />,
      },
      {
        path: "/uoms",
        element: <ProductUoms />,
      },
      {
        path: "/product/blacklist",
        element: <ProductBlackList />,
      },
      {
        path: "/customer/new-customer",
        element: <NewCustomer />,
      },
      {
        path: "/sales",
        element: <Sales />,
      },
      {
        path: "/invoice",
        element: <InvoiceScreen />,
      },
      {
        path: "/invoice/order",
        element: <AddInvoiceScreen />,
      },
      {
        path: "/invoice/show",
        element: <InvoiceDetailScreen />,
      },
      {
        path: "/sales/detail",
        element: <SalesOrderDetail />,
      },
      {
        path: "/sales/new-shipment",
        element: <NewShipment />,
      },
      {
        path: "/sales/edit-shipment",
        element: <EditShipment />,
      },
      {
        path: "/stock-product",
        element: <StockDetail />,
      },
      {
        path: "/add-product",
        element: <FormProduct />,
      },
      {
        path: "/ro",
        element: <ReceiveOrder />,
      },
      {
        path: "/ro-detail",
        element: <ReceiveOrderDetail />,
      },
      {
        path: "/delivery",
        element: <Delivery />,
      },
      {
        path: "/delivery/detail",
        element: <DeliveryDetail />,
      },
      {
        path: "/delivery/detail/update",
        element: <DeliveryOrderUpdate />,
      },
      {
        path: "/qr",
        element: <QRMobile />,
      },
      {
        path: "/sales-order",
        element: <SalesOrderMobile />,
      },
      {
        path: "/stock-opname",
        element: <StockOpname />,
      },
      {
        path: "/stock-opname/details",
        element: <StockOpnameDetails />,
      },
      {
        path: "/stock-opname/details/scan",
        element: <OpnameScanner />,
      },
      {
        path: "/brand",
        element: <BrandMaster />,
      },
      {
        path: "/brand/edit-brand",
        element: <UpdateBrand />,
      },
      {
        path: "/product/detail",
        element: <ProductDetail />,
      },
      {
        path: "/product/detail/edit-detail",
        element: <UpdateProductUnit />,
      },
      {
        path: "/stock/movement",
        element: <StockMovement />,
      },
      {
        path: "/category",
        element: <CategoryMaster />,
      },
      {
        path: "/category/edit-category",
        element: <UpdateCategory />,
      },
      {
        path: "/adjustment/request",
        element: <AdjustmentScreen />,
      },
      {
        path: "/stock-scanner",
        element: <StockScanner />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/new-user",
        element: <NewUser />,
      },
      {
        path: "/users/update-user",
        element: <UpdateUser />,
      },
      {
        path: "/roles",
        element: <Roles />,
      },
      {
        path: "/warehouse",
        element: <Warehouse />,
      },
      {
        path: "/warehouse/update-warehouse",
        element: <UpdateWarehouse />,
      },
      {
        path: "/roles/new-roles",
        element: <NewRoles />,
      },
      {
        path: "/roles/edit-roles",
        element: <EditRoles />,
      },
      {
        path: "/payments-detail",
        element: <PaymentDetailScreen />,
      },
      {
        path: "/master",
        element: <MasterScreen />,
      },
      {
        path: "/voucher",
        element: <VoucherScreen />,
      },
      {
        path: "/voucher/batch",
        element: <BatchAndCategory />,
      },
      {
        path: "/mobile/invoice-order",
        element: <MobileInvoiceList />,
      },
      {
        path: "/mobile/products-unit",
        element: <ProductUnit />,
      },
      {
        path: "/mobile/invoice-order/list",
        element: <InvoiceListScreen />,
      },
      {
        path: "/mobile/invoice-order/create",
        element: <InvoiceCreateScreen />,
      },
      {
        path: "/mobile/invoice-order/detail",
        element: <MobileInvoiceDetailScreen />,
      },
      {
        path: "/invoice/edit",
        element: <EditInvoiceScreen />,
      },
      {
        path: "/mobile/stock",
        element: <StockMobileScreen />,
      },
      {
        path: "/stock-product/scan",
        element: <ScannerScreen />,
      },
    ],
  },
]);
