import { Button, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAxios } from "../../api/hooks/useApi";
import Header from "../../components/molecules/header";
import { queryClient } from "../../provider/query";

interface ScannerScreenProps {}

const ScannerScreen: React.FC<ScannerScreenProps> = () => {
  const [record, setRecord] = useState<string[]>([]); // Stores scanned records
  const [inputValue, setInputValue] = useState<string>(""); // Temporary input value
  const api = useAxios();

  const handleSubmit = () => {
    api
      .post(`stocks/print-verification`, {
        stocks: record,
      })
      .then((res) => {
        toast("Success sync to server");
        setRecord([]);
        setInputValue("");
        queryClient.invalidateQueries("ReceiveStockDetail");

      })
      .catch((err) => {
        toast(err?.axiosError?.response?.data?.message);
      });
  };
  // Handle when a barcode/QR code is scanned
  const handleScan = (data: string | null) => {
    if (data?.length === 26) {
      setRecord((prev) => {
        if (!prev.includes(data)) {
          return [...prev, data]; // Add new scanned data only if it's not already in the array
        }
        return prev; // If it exists, return the previous state unchanged
      });
      setInputValue("");
    }
  };

  useEffect(() => {
    handleScan(inputValue)
  },[inputValue])
  // Handle input change (optional, if you want manual input)

  return (
    <div>
      <Header headerName={"Scan Product"} back={true} navName={"/shipment"} />

      {/* Barcode Scanner */}
      {/* <div className="w-[20vw]">
        <Scanner
          onUpdate={(err, result: any) => {
            if (result) handleScan(result.text); // Pass scanned value to handler
          }}
        />
      </div> */}
      <Button
        onClick={handleSubmit}
        className="mt-5 w-[100%] self-center text-white"
        bg="orange.500"
        
      >
        Sync to Server
      </Button>

      {/* Input Field */}
      <Input
        type="text"
        my={5}
        borderWidth={2}
        borderColor={"#2c3e50"}
        placeholder="Scan your QR Product"
        value={inputValue}
        onChange={(val) => setInputValue(val.target.value)}
      />

      {/* Display Scanned Records */}
      <div>
        <div className="flex justify-between border-b-2 mb-5">
        <p className="font-semibold text-xl pb-2 mb-2">
          Scanned Records:
        </p>
        <p className="font-semibold text-xl pb-2 mb-2">
          {record?.length}
        </p>
        </div>
        <ul className="grid-container">
          {record.map((item, index) => (
            <li key={index} className="grid-item">
              • {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ScannerScreen;
